@import breakpoints

//Layout Standard Width
$width: 100%
$standard_max_width: 110rem

// Radius
$radius: .5rem

//Layout Standard Height
$min_height: 100vh
$max_height: 90rem
$height: 100vh

// Header bar height
$header_height: 9rem

// Margins
$section_margin: 7.5rem

// Paddings
$padding: 3rem
$padding-sm: 1.5rem
$padding-sides: .5rem

// Primary Colors | Changeable
$rose: #ddcabe
$angel: #DED9CD
$dark_angel: #CDC3B8
$grey: #B9B399
$red: #b53612
$green: green

// Not that changeable
$black: #222222
$white: #ffffff
$grey: #E7E7E7
$dark_grey: #7E7E7E

// Fonts
@font-face
    font-family: 'worksans'
    src: url('../fonts/Work_Sans/WorkSans-VariableFont_wght.ttf') format("truetype")

$font-main: 'worksans'
$font-title: 'worksans'
