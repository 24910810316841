textarea
    $padding: 1.5rem

    width: 30rem
    height: 10rem
    font-family: $font-main
    font-size: 1.6rem
    padding: $padding
    outline: none
    border: 1px solid $white
    background-color: $white
    border-radius: $radius !important
    -webkit-appearance: none

    resize: vertical

    &:focus
        border: .1rem solid $black

    &--testSize
        height: 300px
        width: 400px
