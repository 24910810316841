*,
*::after,
*::before
    margin: 0
    padding: 0
    box-sizing: inherit


html
    font-size: 62.5%
    font-family: $font-main
    scroll-behavior: smooth

.hide
    display: none



body
    font-weight: 400
    line-height: 1.7
    color: $black
    overflow-x: hidden
    box-sizing: border-box
    background-color: $grey

span,
textarea,
input,
select
    font-family: $font-main

// Typography
a
    letter-spacing: 1px
    color: inherit


a,
a:link,
a:active
    text-decoration: none
    z-index: 500


ul, li
    list-style: none

h1
    font-weight: 500
    font-size: inherit

h2, h3
    font-weight: 400
    font-size: inherit


.error
  border-color: $red !important

  &::placeholder
    color: $red

.success
  border-color: $green !important
  color: $green

  &:focus
    border-color: $green

.imageAspectRatio
  width: 100%
  height: auto
  background-color: $dark_angel
  //padding-top: 75% /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
  padding-top: 150%
  /* 2:3 Aspect Ratio (divide 2 by 3 = 1.5) */
  position: relative
  border-radius: $radius
  overflow: hidden

  img
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: auto

    object-fit: cover
    object-position: center

    width: 100%
    height: 100%

.disableScrollbar
  scrollbar-width: none

  &::-webkit-scrollbar
    display: none